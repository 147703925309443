import { ApiAxios } from '../axios/axiosConfig'
import moment from 'moment/moment'

export async function login(email, password) {
	return ApiAxios.post(
		`/auth/login`,
		{
			username: email,
			password: password
		},
		{
			headers: {
				LoginToken: process.env.REACT_APP_LOGIN_TOKEN
			}
		}
	)
		.then(res => {
			if (res.data) {
				return {
					degree: res.data.data.citizen.degree,
					degreeAfter: res.data.data.citizen.degreeAfter,
					firstName: res.data.data.citizen.firstName, // res.data.firstName
					lastName: res.data.data.citizen.lastName, // res.data.lastName
					email: res.data.data.citizen.email, // res.data.email
					credit: res.data.data.creditBalance, // res.data.credit
					token: res.data.data.sessionToken
				}
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function register(data) {
	return ApiAxios.post(
		`/auth/signup`,
		{
			...data,
			birthday: moment(data.birthday).format('YYYY-MM-DD')
		},
		{
			headers: {
				LoginToken: process.env.REACT_APP_LOGIN_TOKEN
			}
		}
	)
		.then(res => {
			if (res.data) {
				return res.data.result
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function logout() {
	return ApiAxios.delete(`/auth/logout`)
		.then(res => {
			if (res.data) {
				return true
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function authenticate() {
	return ApiAxios.get(`/auth`)
		.then(res => {
			if (res.data) {
				return {
					degree: res.data.data.citizen.degree,
					degreeAfter: res.data.data.citizen.degreeAfter,
					firstName: res.data.data.citizen.firstName, // res.data.firstName
					lastName: res.data.data.citizen.lastName, // res.data.lastName
					email: res.data.data.citizen.email, // res.data.email
					credit: res.data.data.creditBalance, // res.data.credit
					token: res.data.data.sessionToken
				}
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function getEmailToResetPassoword(email) {
	return ApiAxios.put(`/citizen/reset-password`, {
		...email
	})
		.then(res => {
			if (res.data) {
				return res.data.result
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function setNewPassword(pass, token) {
	return ApiAxios.put(`/citizen/set-password-by-reset-password-token`, {
		token: token,
		password: pass
	})
		.then(res => {
			if (res.data) {
				return res.data.result
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function setNewPasswordFromAccount(data) {
	return ApiAxios.put(`/citizen/set-password`, {
		oldPassword: data.oldPassword,
		newPassword: data.password
	})
		.then(res => {
			if (res.data) {
				return res.data.result
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function checkWelcomeToken(tokenParam) {
	return await ApiAxios.post(`/citizen/check-welcome-token`, {
		token: tokenParam
	})
		.then(res => {
			if (res.data) {
				return res.data
			}
			return null
		})
		.catch(err => {
			console.error(err)
			return null
		})
}

export async function checkResetPasswordToken(tokenParam) {
	return await ApiAxios.post(`/citizen/check-reset-password-token`, {
		token: tokenParam
	})
		.then(res => {
			if (res.data) {
				return res.data
			}
			return null
		})
		.catch(err => {
			console.error(err)
			return null
		})
}

export async function setPasswordByWelcomeToken(tokenParam, password) {
	return await ApiAxios.put(`/citizen/set-password-by-welcome-token`, {
		token: tokenParam,
		password: password
	})
		.then(res => {
			if (res.data) {
				return res.data
			}
			return null
		})
		.catch(err => {
			console.error(err)
			return null
		})
}
