import React, { useEffect, useState } from 'react'
import FaqItem from '../../compontents/FaqItem'
import PortalHeader from '../../compontents/PortalHeader'
import { toast } from 'react-toastify'
import { getFaq } from '../../api/FaqApi'
import BoxContent from '../../compontents/BoxContent'

const Faq = () => {
	const [faq, setFaq] = useState(null)

	useEffect(() => {
		fetchFaqs().then(data => setFaq(data.faqList))
	}, [])

	async function fetchFaqs() {
		try {
			return await getFaq()
		} catch (e) {
			console.error(e.message || e)
			toast.error('Při zpracování požadavku došlo k chybě.')
		}
	}

	const renderFaqs = () => {
		if (faq && faq.length > 0) {
			return faq.map((item, index) => (
				<FaqItem
					key={index}
					question={item.question}
					answer={item.answer}
				/>
			))
		} else {
			return <div>Momentálně zde nejsou žádné dotazy a odpovědi.</div>
		}
	}

	return (
		<div>
			<PortalHeader
				headline={'Časté dotazy'}
				subtitle={'Co naše občany nejčastěji zajímá.'}
			/>
			<div className="container max-w-5xl mx-auto">
				<BoxContent label={'Otázky a odpovědi'}>
					{renderFaqs()}
				</BoxContent>
			</div>
		</div>
	)
}

export default Faq
