import React from 'react'
import PortalHeader from '../../compontents/PortalHeader'

const Settings = () => {
	return (
		<div>
			<PortalHeader headline={'Nastavení'} />
		</div>
	)
}

export default Settings
