import React, {useEffect, useState} from 'react'
import PortalHeader from '../../../compontents/PortalHeader'
import BoxContent from '../../../compontents/BoxContent'
import {useNavigate, useParams} from "react-router-dom";
import {ApiAxios} from "../../../axios/axiosConfig";
import {toast} from "react-toastify";
import Loader from "../../../compontents/Loader";
import moment from "moment/moment";

const CreditValidation = () => {

	let { id: transactionId } = useParams()
	const navigate = useNavigate()
	const [transactionData, setTransactionData] = useState(null)

	useEffect(() => {
		if (!transactionId) {
			toast.info('ID nenalezeno')
			navigate('/portal/historie')
		}

		ApiAxios.get(`/creditTransaction/${transactionId}`)
			.then(res => {
				console.log(res.data.data.creditTransaction)
				setTransactionData(res.data.data.creditTransaction)
			})
			.catch(err => {
				toast.error('Chyba při načtení dat')
				navigate('/portal/historie')
			})
	}, [navigate, transactionId])

	const _renderTransactionData = () => (
		<div>
			<div className={'flex justify-between'}>
				<div>ID transakce</div>
				<div>{transactionData._id}</div>
			</div>
			<div className={'flex justify-between'}>
				<div>Datum a čas</div>
				<div>{moment(transactionData.datetime)
					.local()
					.format('DD.MM.YYYY HH:mm:ss')}</div>
			</div>
			<div className={'flex justify-between'}>
				<div>Částka</div>
				<div>{transactionData.amount}</div>
			</div>
			<div className={'flex justify-between'}>
				<div>Stav účtu po transakci</div>
				<div>{transactionData.balance}</div>
			</div>
			<div className={'flex justify-between'}>
				<div>Popis</div>
				<div>{transactionData.description}</div>
			</div>
		</div>
	);

	const _renderOrderData = () => (
		<div className={'border-t-2 mt-3 pt-3'}>
			<div className={'flex justify-between'}>
				<div>ID objednávky</div>
				<div>{transactionData.order._id}</div>
			</div>
			<div className={'flex justify-between'}>
				<div>Datum a čas</div>
				<div>{moment(transactionData.order.datetime)
					.local()
					.format('DD.MM.YYYY HH:mm:ss')}</div>
			</div>
			<div className={'flex justify-between'}>
				<div>Číslo objednávky</div>
				<div>{transactionData.order.orderNumber}</div>
			</div>
			<div className={'flex justify-between'}>
				<div>Stav</div>
				<div>{transactionData.order.state}</div>
			</div>
		</div>
	);

	return (
		<>
			<PortalHeader headline={`Transakce ${transactionData && transactionData._id}`} />
			<BoxContent additionalClass={'max-w-2xl mx-auto'}>
				{transactionData ?
					<>
						{_renderTransactionData()}
						{transactionData.order && _renderOrderData()}
					</>
					: <Loader/>
				}
			</BoxContent>
		</>
	)
}

export default CreditValidation
