import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { publicRoutes } from '../../variables/routes'

const PublicRoutes = () => {
	return (
		<Routes>
			{publicRoutes.map((item, index) => (
				<Route
					key={item.path}
					path={item.path}
					element={
						item.layout ? (
							<item.layout>{<item.component />}</item.layout>
						) : (
							<item.component />
						)
					}
				/>
			))}
			<Route path={'*'} element={<div>Page not found</div>} />
		</Routes>
	)
}

export default PublicRoutes
