import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PortalRoutes from 'compontents/routes/PortalRoutes'
import PublicRoutes from './PublicRoutes'

const RouterFactory = () => {
	return (
		<Suspense fallback={<div>Loading</div>}>
			<Router>
				<Routes>
					{/* Portal pages */}
					<Route path="/portal/*" element={<PortalRoutes />} />
					{/* Public pages */}
					<Route path="/*" element={<PublicRoutes />} />
				</Routes>
			</Router>
		</Suspense>
	)
}

export default RouterFactory
