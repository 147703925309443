import React, { useState, useEffect, Fragment } from 'react'
import Loader from '../../../compontents/Loader'
import PortalHeader from '../../../compontents/PortalHeader'
import { NumericFormat } from 'react-number-format'
import { toast } from 'react-toastify'
import { getCreditTransactions } from '../../../api/CreditTransactionApi'
import moment from 'moment'
import BoxContent from '../../../compontents/BoxContent'
import { Link } from 'react-router-dom'

const TransactionHistory = () => {
	const [data, setData] = useState(null)
	const [paginator, setPaginator] = useState(null)
	let page = 0
	let offset = 0
	const limit = 10

	useEffect(() => {
		getCreditTransactionList(page, offset, limit).then(() => {
			console.info('Data loaded..')
		})
	}, [offset, page])

	async function getCreditTransactionList(page, offset, limit) {
		try {
			const data = await getCreditTransactions(page, offset, limit)
			setPaginator(data.paginator)
			setData(data.creditTransactionList)
		} catch (e) {
			console.error(e.message || e)
			toast.error('Při zpracování požadavku došlo k chybě.')
		}
	}

	const renderData = () => {
		return data.map((item, index) => (
			<div
				key={index}
				className={
					'grid grid-child-template-transaction border-b py-4 gap-3'
				}
			>
				<div className={'custom-col'}>
					<div className={'block'}>
						<span
							title={
								item.touchPoint ? item.touchPoint.name : null
							}
							className={`icon-${item.touchPoint ? item.touchPoint.key : 'benesov'} text-3xl text-secondary-300 w-4 h-4 bg-primary-300 rounded-full p-1 xl:ml-4`}
						></span>
					</div>
				</div>
				<div className={'custom-col'}>
					<div className={'custom-col font-bold'}>
						{item.touchPoint ? item.touchPoint.name : null}
					</div>
					<div className={'custom-col text-lg'}>
						{moment(item.datetime)
							.local()
							.format('DD.MM.YYYY HH:mm')}
					</div>
				</div>
				<div className={'custom-col'}>
					<div className={'custom-title'}>Množství</div>
					<div
						className={`custom-col font-bold text-lg ${item.type === 'add' ? 'text-green-500' : 'text-red-500'}`}
					>
						<NumericFormat
							value={item.amount}
							decimalScale={2}
							allowLeadingZeros
							thousandSeparator=","
							suffix={' Bene'}
							displayType={'text'}
						/>
					</div>
				</div>
				<div className={'custom-col'}>
					{item.description && (
						<Fragment>
							<div className={'custom-title'}>Poznámka</div>
							<div className={'custom-col'}>
								{item.description}
							</div>
						</Fragment>
					)}
				</div>
				<div className={'custom-col font-bold'}>
					<div className={'custom-title'}>Zůstatek</div>
					<div className={'custom-col text-lg'}></div>
					<NumericFormat
						value={item.balance}
						decimalScale={2}
						allowLeadingZeros
						thousandSeparator=","
						suffix={' Bene'}
						displayType={'text'}
					/>
				</div>
				<div
					className={
						'text-xs text-gray-600 px-0 xl:px-3 col-span-full'
					}
				>
					ID:{' '}
					<Link to={`/portal/historie/dobiti-kreditu/validace/${item._id}`} className={'text-primary-200 font-semibold'}>
						{item._id}
					</Link>
				</div>
			</div>
		))
	}

	const renderPaginator = () => {
		if (paginator && paginator.totalPages > 1) {
			return (
				<div className={'mt-8 text-center space-x-3'}>
					{paginator.currentPageNumber > 0 && (
						<button
							className={'underline'}
							onClick={() => previousPage()}
						>
							Předchozí
						</button>
					)}
					<span>
						Stránka {paginator.currentPageNumber + 1}/
						{paginator.totalPages}
					</span>
					{paginator.currentPageNumber + 1 < paginator.totalPages && (
						<button
							className={'underline'}
							onClick={() => nextPage()}
						>
							Další
						</button>
					)}
				</div>
			)
		}
	}

	const previousPage = () => {
		let offset = (paginator.currentPageNumber - 1) * limit
		getCreditTransactionList(paginator.currentPageNumber - 1, offset, limit)
	}

	const nextPage = () => {
		let offset = (paginator.currentPageNumber + 1) * limit
		getCreditTransactionList(paginator.currentPageNumber + 1, offset, limit)
	}

	return (
		<div>
			<PortalHeader headline={'Historie transakcí'} />
			<BoxContent
				additionalClass={'max-w-5xl mx-auto'}
				btnLink={'/portal/historie/dobiti-kreditu'}
				btnText={'Dobít kredit'}
				showButton={true}
				label={'Historie transakcí'}
			>
				{paginator && data ? (
					<>
						{paginator.totalItems > 0 ? (
							<div className={'grid custom-table'}>
								<div
									className={
										'grid grid-child-template-transaction hidden font-bold text-xl py-3 bg-secondary-300 gap-3 xl:grid'
									}
								>
									<div className={'custom-col ml-4'}>
										Vstup
									</div>
									<div className={'custom-col'}>Datum</div>
									<div className={'custom-col'}>Množství</div>
									<div className={'custom-col'}>Poznámka</div>
									<div className={'custom-col'}>Zůstatek</div>
								</div>
								{renderData()}
							</div>
						) : (
							<div className={'text-center text-xl'}>
								<div>
									Aktuálně zde nejsou žádná data k zobrazení.
								</div>
							</div>
						)}
						{renderPaginator()}
					</>
				) : (
					<div className={'flex w-full justify-center'}>
						<Loader />
					</div>
				)}
			</BoxContent>
		</div>
	)
}

export default TransactionHistory
