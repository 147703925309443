import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'compontents/ProtectedRoute/ProtectedRoute'
import Dashboard from 'pages/Portal/Dashboard'
import TransactionHistory from 'pages/Portal/CreditTransaction/TransactionHistory'
import FAQ from 'pages/Portal/Faq'
import MyAccount from '../../pages/Portal/MyAccount/MyAccount'
import CitizenCard from '../../pages/Portal/CitizenCard'
import FamilyMembers from '../../pages/Portal/FamilyMember/FamilyMembers'
import Settings from '../../pages/Portal/Settings'
import Support from '../../pages/Portal/Support'
import FamilyMemberDetail from '../../pages/Portal/FamilyMember/FamilyMemberDetail'
import EditAccount from '../../pages/Portal/MyAccount/EditAccount'
import FamilyMemberEdit from '../../pages/Portal/FamilyMember/FamilyMemberEdit'
import AddCredit from '../../pages/Portal/CreditTransaction/AddCredit'
import CreditValidation from "../../pages/Portal/CreditTransaction/CreditValidation";
import TransactionSummary from "../../pages/Portal/CreditTransaction/TransactionSummary";

const PortalRoutes = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="prehled" />} />
			<Route
				path="prehled"
				element={
					<ProtectedRoute>
						<Dashboard />
					</ProtectedRoute>
				}
			/>
			<Route
				path="muj-ucet"
				element={
					<ProtectedRoute>
						<MyAccount />
					</ProtectedRoute>
				}
			/>
			<Route
				path="muj-ucet/upravit"
				element={
					<ProtectedRoute>
						<EditAccount />
					</ProtectedRoute>
				}
			/>
			<Route
				path="karta-obcana"
				element={
					<ProtectedRoute>
						<CitizenCard />
					</ProtectedRoute>
				}
			/>
			<Route
				path="historie"
				element={
					<ProtectedRoute>
						<TransactionHistory />
					</ProtectedRoute>
				}
			/>
			<Route
				path="historie/dobiti-kreditu"
				element={
					<ProtectedRoute>
						<AddCredit />
					</ProtectedRoute>
				}
			/>
			<Route
				path="historie/dobiti-kreditu/validace"
				element={
					<ProtectedRoute>
						<CreditValidation />
					</ProtectedRoute>
				}
			/>
			<Route
				path="historie/dobiti-kreditu/validace/:id"
				element={
					<ProtectedRoute>
						<TransactionSummary />
					</ProtectedRoute>
				}
			/>
			<Route
				path="clenove-rodiny"
				element={
					<ProtectedRoute>
						<FamilyMembers />
					</ProtectedRoute>
				}
			/>
			<Route
				path="clenove-rodiny/:id"
				element={
					<ProtectedRoute>
						<FamilyMemberDetail />
					</ProtectedRoute>
				}
			/>
			<Route
				path="clenove-rodiny/editace/:id"
				element={
					<ProtectedRoute>
						<FamilyMemberEdit />
					</ProtectedRoute>
				}
			/>
			<Route
				path="nastaveni"
				element={
					<ProtectedRoute>
						<Settings />
					</ProtectedRoute>
				}
			/>
			<Route
				path="caste-dotazy"
				element={
					<ProtectedRoute>
						<FAQ />
					</ProtectedRoute>
				}
			/>
			<Route
				path="podpora"
				element={
					<ProtectedRoute>
						<Support />
					</ProtectedRoute>
				}
			/>
		</Routes>
	)
}

export default PortalRoutes
