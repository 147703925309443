import React from 'react'

const PortalHeader = ({ headline, subtitle, children }) => {
	return (
		<div>
			<div className={'text-center mb-10'}>
				<h1 className={'text-4xl mb-3'}>{headline}</h1>
				{subtitle && <p className={'text-gray-400'}>{subtitle}</p>}
			</div>
			{children}
		</div>
	)
}

export default PortalHeader
