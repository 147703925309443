import React from 'react'
import moment from 'moment'
import BoxContent from '../BoxContent'

const CitizenDetail = ({ citizen, isChild }) => {
	let editUrl = isChild
		? `/portal/clenove-rodiny/editace/${citizen._id}`
		: `/portal/muj-ucet/upravit`

	return (
		<BoxContent
			label={'Základní informace'}
			btnLink={editUrl}
			btnText={'Upravit profil'}
			showButton={true}
		>
			<div className="flex flex-col sm:flex-row">
				<div>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-1">
							Jméno a příjmení
						</p>
						<p className="text-gray-600">
							{citizen.degree} {citizen.firstName}{' '}
							{citizen.lastName} {citizen.degreeAfter}
						</p>
					</div>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-1">
							Pohlaví
						</p>
						<p className="text-gray-600">
							{citizen.gender === 'MAN' ? 'Muž' : 'Žena'}
						</p>
					</div>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-1">
							Datum narození
						</p>
						<p className="text-gray-600">
							{moment(citizen.birthday).format('DD. MM. YYYY')}
						</p>
					</div>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-1">
							Adresa trvalého bydliště
						</p>
						<p className="text-gray-600">
							{citizen.city}, {citizen.street}{' '}
							{citizen.houseNumber}, {citizen.zip}
						</p>
					</div>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-1">
							Kontaktní údaje
						</p>
						<p className="text-gray-600">E-mail: {citizen.email}</p>
						<p className="text-gray-600">
							Telefon: {citizen.phone}
						</p>
					</div>
				</div>
				<div>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-3">
							Přístup
						</p>
						<div className="flex flex-row flex-wrap gap-3">
							{citizen.touchPoints.map((item, index) => (
								<div
									key={index}
									className={
										'bg-primary-300 text-secondary-300 rounded-lg w-28 h-28 flex flex-col items-center justify-center text-2xl'
									}
								>
									<span
										className={`icon-${item.key} text-4xl`}
									></span>
									<p className={'text-sm'}>{item.name}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</BoxContent>
	)
}

export default CitizenDetail
