import React from 'react'
import { Link } from 'react-router-dom'

const SidebarLink = ({ icon, label, link }) => {
	return (
		<li>
			<Link
				to={link}
				className={
					'flex w-full justify-items-start items-center px-7 py-5 block text-primary-300 hover:bg-yellow-300'
				}
			>
				{icon} <span className={'ml-4 text-sm'}>{label}</span>
			</Link>
		</li>
	)
}

export default SidebarLink
