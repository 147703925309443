import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../redux/slices/userslice'
import { toast } from 'react-toastify'
import { userAuthStatus } from '../redux/selectors/userSelectors'

export const useAuthCheckAndRedirect = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isLoggedIn = useSelector(userAuthStatus)

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(logoutUser())
			navigate('/prihlaseni')
			toast.info('Byli jste odhlášeni')
		}
	}, [isLoggedIn, dispatch, navigate])
}
