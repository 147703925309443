import React from 'react'

const Badge = ({ type, text }) => {
	const getTypeStyles = type => {
		switch (type) {
			case 'success':
				return 'bg-green-100 text-green-800'
			case 'danger':
				return 'bg-red-100 text-red-800'
			case 'error':
				return 'bg-yellow-100 text-yellow-800'
			default:
				return 'bg-gray-100 text-gray-800'
		}
	}

	return (
		<span
			className={`text-xs font-semibold px-2.5 py-0.5 rounded ${getTypeStyles(type)}`}
		>
			{text}
		</span>
	)
}

export default Badge
