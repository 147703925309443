import React from 'react'
import moment from 'moment'

const CitizenCardList = ({ data }) => {
	return (
		<div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
			{data.map((item, index) => (
				<div
					key={index}
					className="w-full bg-hero-image bg-cover rounded-xl overflow-hidden shadow-lg text-white h-64 max-w-[416px] mx-auto"
				>
					<div className="bg-primary-300 bg-opacity-25 flex flex-col justify-between h-full">
						<div
							className={
								'bg-primary-300 px-3 py-2 flex justify-between items-end'
							}
						>
							<p className={'text-xl font-bold leading-none'}>
								<span className={'text-secondary-300'}>
									Bene
								</span>
								šov
								<s100vhpan className={'text-secondary-300'}>
									<sup
										className={'icon-benesov text-md'}
									></sup>
								</s100vhpan>
								<br />
								Bene
								<span className={'text-secondary-300'}>
									fit!
								</span>
							</p>
							<p className={'text-sm'}>
								Počítejte s námi{' '}
								<span className={'text-secondary-300'}>
									Bene...
								</span>
								<span
									className={
										'text-3xl font-bold text-secondary-300 pl-1'
									}
								>
									fit!
								</span>
							</p>
						</div>
						<div className={'p-4'}>
							<div className="text-sm">
								Platná do
								<br />
								<span className="text-lg">
									{moment(item.expirationDate)
										.local()
										.format('MM/YY')}
								</span>
							</div>
						</div>
						<div className={'p-4'}>
							<div className="text-2xl font-medium space-y-3">
								**** **** **** {item.cardNumber.slice(-4)}
							</div>
							<div className="uppercase mb-1">
								{item.type === 'ELECTRONIC'
									? 'Elektronická'
									: 'Fyzická'}
							</div>
							<div
								className={`text-sm px-1.5 py font-semibold rounded bg-opacity-80 table ${item.active ? 'bg-green-300 text-green-700' : 'bg-red-300 text-red-700'}`}
							>
								{item.active ? 'Aktivní' : 'Neaktivní'}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

export default CitizenCardList
