import { ApiAxios } from '../axios/axiosConfig'

export async function getCitizen() {
	return ApiAxios.get(`/citizen`)
		.then(res => {
			if (res.data) {
				return res.data.data
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function getChildren() {
	return ApiAxios.get(`/citizen/children`)
		.then(res => {
			if (res.data) {
				return res.data.data
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function getChild(id) {
	return ApiAxios.get(`/citizen/child/${id}`)
		.then(res => {
			if (res.data) {
				return res.data.data
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function updateCitizen(data) {
	return ApiAxios.put(`/citizen`, data)
		.then(res => {
			if (res.data.result) {
				return res.data.result
			} else {
				return false
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}

export async function updateChild(data, childId) {
	return ApiAxios.put(`/citizen/child/${childId}`, data)
		.then(res => {
			if (res.data.result) {
				return res.data.result
			} else {
				return false
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}
