import inflect from 'czech-inflection'

const PortalGreeting = ({ name, gender }) => {
	const currentHour = new Date().getHours()
	let greeting
	let address = inflect({
		word: name,
		grammarCase: 5,
		animate: false,
		gender: gender
	})

	if (currentHour >= 5 && currentHour < 12) {
		greeting = 'Dobré ráno, ' + address + '!'
	} else if (currentHour >= 12 && currentHour < 18) {
		greeting = 'Dobrý den, ' + address + '!'
	} else {
		greeting = 'Dobrý večer, ' + address + '!'
	}

	return greeting
}

export default PortalGreeting
