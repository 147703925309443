import React, { useEffect, useState } from 'react'
import PortalHeader from '../../../compontents/PortalHeader'
import BoxContent from '../../../compontents/BoxContent'
import { Link, useSearchParams } from 'react-router-dom'
import { ApiAxios } from '../../../axios/axiosConfig'
import { toast } from 'react-toastify'
import Loader from '../../../compontents/Loader'
import FeatherIcon from 'feather-icons-react'

const CreditValidation = () => {
	const [searchParams] = useSearchParams()
	const paramsObject = Object.fromEntries(searchParams.entries())
	const [transactionId, setTransactionId] = useState(null)
	const [isError, setPaymentErrorState] = useState(false)

	useEffect(() => {
		let paymentData = {
			payId: paramsObject.payId,
			dttm: paramsObject.dttm,
			resultCode: parseInt(paramsObject.resultCode),
			resultMessage: paramsObject.resultMessage,
			paymentStatus: parseInt(paramsObject.paymentStatus),
			signature: paramsObject.signature,
			...(paramsObject.authCode
				? { authCode: parseInt(paramsObject.authCode) }
				: {})
		}


		ApiAxios.post(`/payment/processResult`, paymentData)
			.then(res => {
				toast.success('Úspěšná transakce')
				setTransactionId(res.data.data.creditTransaction._id)
				window.location.replace(
					`/portal/historie/dobiti-kreditu/validace/${res.data.data.creditTransaction._id}`
				)
			})
			.catch(err => {
				setPaymentErrorState(true)
				toast.error('Během zpracování došlo k chybě')
				console.error(err)
			})
	}, [paramsObject])

	return (
		<>
			<PortalHeader headline={'Ověření platby'} />
			<BoxContent additionalClass={'max-w-2xl mx-auto'}>
				{transactionId ? (
					<div className={'mt-6 text-center block'}>
						<span className={'block mb-4'}>
							Pokud nejdojde k automatickému přesměrování, může
							použít odkaz níže.
						</span>
						<Link
							to={`/portal/historie/dobiti-kreditu/validace/${transactionId}`}
							className={'button-primary'}
						>
							Detail transakce
						</Link>
					</div>
				) : (
					<>
						{isError ? (
							<div className={'text-center'}>
								<FeatherIcon
									icon={'x-circle'}
									className={'text-red-500 mx-auto'}
									size={150}
								/>
								<p className={'text-2xl font-medium my-5'}>
									Transakci nebylo možné zpracovat
								</p>
								<div className={'mb-3'}>
									<Link
										to={`/portal/historie/`}
										className={'button-primary'}
									>
										Historie transakcí
									</Link>
								</div>
							</div>
						) : (
							<Loader />
						)}
					</>
				)}
			</BoxContent>
		</>
	)
}

export default CreditValidation
