import AuthLayout from '../compontents/Layout/AuthLayout'
import Layout from '../compontents/Layout/Layout'
import Empty from '../compontents/Layout/Empty'
import { lazy } from 'react'

const ForgotPassword = lazy(() => import('../pages/Auth/ForgotPassword'))
const RecoveryPassword = lazy(() => import('../pages/Auth/RecoveryPassword'))
const Verify = lazy(() => import('../pages/Auth/Verify'))
const Login = lazy(() => import('../pages/Auth/Login'))
const Registration = lazy(() => import('../pages/Auth/Registration'))
const Homepage = lazy(() => import('../pages/Homepage/Homepage'))
const SmartQR = lazy(() => import('../pages/SmartQR/SmartQR'))

export const publicRoutes = [
	{
		name: 'Verifikace účtu',
		path: '/verifikace-uctu',
		icon: null,
		layout: AuthLayout,
		component: Verify
	},
	{
		name: 'Zapomenuté heslo',
		path: '/zapomenute-heslo',
		icon: null,
		layout: AuthLayout,
		component: ForgotPassword
	},
	{
		name: 'Obnova hesla',
		path: '/obnovit-heslo',
		icon: null,
		layout: AuthLayout,
		component: RecoveryPassword
	},
	{
		name: 'Přihlášení',
		path: '/prihlaseni',
		icon: null,
		layout: AuthLayout,
		component: Login
	},
	{
		name: 'Registrace',
		path: '/registrace',
		icon: null,
		layout: AuthLayout,
		component: Registration
	},
	{
		name: 'SmartQR',
		path: '/smartqr',
		icon: null,
		layout: Empty,
		component: SmartQR
	},
	{
		name: 'Homepage',
		path: '/',
		icon: null,
		layout: Layout,
		component: Homepage
	}
]
