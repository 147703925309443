import React from 'react'
import { Link } from 'react-router-dom'

const UserName = ({ userData }) => {
	return (
		<span className={'text-white'}>
			{userData && (
				<Link to={'/portal/muj-ucet'}>
					<div
						className={
							'block sm:hidden rounded-full bg-secondary-400 text-primary-300 px-1 font-bold'
						}
					>
						<span>
							{userData.firstName.charAt(0) +
								'' +
								userData.lastName.charAt(0)}
						</span>
					</div>
					<span className={'hidden sm:block'}>
						{userData.firstName + ' ' + userData.lastName}
					</span>
				</Link>
			)}
		</span>
	)
}

export default UserName
