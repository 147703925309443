import { ApiAxios } from '../axios/axiosConfig'

export async function getFaq() {
	return ApiAxios.get(`/faqs`)
		.then(res => {
			if (res.data) {
				return res.data.data
			}
		})
		.catch(err => {
			console.error(err.message || err)
			return false
		})
}
