import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { NumericFormat } from 'react-number-format'
import NumberFormatCustom from '../../NumberFormatCustom'
import { useSelector } from 'react-redux'
import { userCreditSelector } from '../../../redux/selectors/userSelectors'
import { ApiAxios } from '../../../axios/axiosConfig'

const ChangePasswordForm = () => {

	let userCredit = useSelector(userCreditSelector)

	const amountResolver = yup.object().shape({
		amount: yup
			.number()
			.typeError('Zadejte platné číslo')
			.min(100, 'Minimální částka je 100 Kč')
			.required('Částka je povinná')
			.test("max-credit", `Zůstatek nemůže být vyšší než 3 000 Bene.`, (value) => {
				return userCredit + value <= 3000;
			}),
	})

	const [predefinedAmounts] = useState([100, 300, 500, 1000])

	const {
		handleSubmit,
		setValue,
		watch,
		control,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(amountResolver),
		defaultValues: {amount: 0},
	})

	const onSubmit = async (data, e) => {
		if (data.amount < 99) {
			toast.error('Částka musí být alespoň 100 Kč')
			return
		}
		try {
			// Endpoint: /api/v1/portal/payment/createOrder
			ApiAxios.post(`/payment/createOrder`, {
				description: 'Dobití kreditu',
				amount: data.amount,
				returnUrl: `${window.location.origin}/portal/historie/dobiti-kreditu/validace`,
				returnMethod: 'GET'
			})
				.then(res => {
					toast.info('Budete přesměrováni do platební brány')

					/* Přesměrování do platební brány */
					window.location.replace(res.data.data.redirectUrl);
				})
				.catch(err => {
					toast.error('Při zpracování platby došlo k chybě')
				})
		} catch (err) {
			toast.error('Došlo k chybě')
			console.error(err.message || err)
		}
	}

	let amount = watch('amount')

	return (
		<div>
			<form
				className="space-y-4 md:space-y-6"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className={'flex gap-5'}>
					{predefinedAmounts.map((amountValue, index) => (
						<div
							key={index}
							className={
								'bg-secondary-300 hover:bg-secondary-400 transition ease-in-out text-primary-300 px-3 py-2 rounded-lg'
							}
							onClick={() => setValue('amount', amountValue)}
						>
							{amountValue} Kč
						</div>
					))}
				</div>
				<Controller
					name="amount"
					control={control}
					defaultValue={0}
					render={({ field }) => (
						<>
							<NumberFormatCustom
								{...field}
								thousandSeparator=" "
								decimalScale={0}
								fixedDecimalScale
								suffix=" Kč"
								allowNegative={false}
								className={errors.amount ? 'border-red-500' : ''}
							/>
							{errors.amount && (
								<p className="text-red-500 text-sm !mt-1">{errors.amount.message}</p>
							)}
						</>
					)}
				/>
				<div className="flex justify-between">
					<div>
						Aktuální zůstatek: <strong>{userCredit} Bene</strong>
					</div>
				</div>
				<button
					type="submit"
					disabled={userCredit + amount > 3000}
					className={`button-primary ${userCredit + amount > 3000 ? 'disabled' : ''}`}
				>
					Zaplatit
				</button>
			</form>
		</div>
	)
}

export default ChangePasswordForm
