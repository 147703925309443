import React from 'react'
import { useSelector } from 'react-redux'
import {
	userCreditSelector,
	userDataSelector
} from '../../redux/selectors/userSelectors'
import { Link } from 'react-router-dom'
import UserName from './UserName'
import UserCredit from './UserCredit'
import FeatherIcon from 'feather-icons-react'

const PortalNavbar = ({ showSidebar, toggleSidebar }) => {
	const userSelector = useSelector(userDataSelector)
	const userCredit = useSelector(userCreditSelector)

	return (
		<ul
			className={
				'flex items-center justify-between bg-primary-300 py-4 px-5'
			}
		>
			<div className="flex flex-shrink-0 items-center">
				<div
					className={'text-white mr-3 block lg:hidden cursor-pointer'}
				>
					{showSidebar ? (
						<FeatherIcon
							icon={'x'}
							onClick={() => toggleSidebar()}
						/>
					) : (
						<FeatherIcon
							icon={'menu'}
							onClick={() => toggleSidebar()}
						/>
					)}
				</div>
				<Link to={'/'}>
					<h1 className={'text-white font-bold text-sm lg:text-xl'}>
						<span className={'text-secondary-300'}>Bene</span>šov
						<span className={'text-secondary-300'}>
							<sup className={'icon-benesov text-sm '}></sup>
							<span className={'block md:inline-block'}></span>
						</span>
						Bene<span className={'text-secondary-300'}>fit!</span>
					</h1>
				</Link>
			</div>
			<div className={'flex gap-x-5'}>
				<UserCredit userCredit={userCredit} />
				<UserName userData={userSelector} />
			</div>
		</ul>
	)
}

export default PortalNavbar
