import React from 'react'
import PortalHeader from '../../compontents/PortalHeader'
import BoxContent from '../../compontents/BoxContent'

const Support = () => {
	return (
		<div>
			<PortalHeader headline={'Podpora'} />
			<div className="container max-w-5xl mx-auto">
				<BoxContent label={'Kontaktní údaje'}>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-1">
							Technické služby Benešov
						</p>
					</div>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-1">
							Adresa:
						</p>
						<p className="text-gray-600">
							Na Spořilově 1371, Benešov u Prahy 256 01
						</p>
					</div>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-1">
							E-mail:
						</p>
						<p className="text-gray-600">
							<a
								href="mailto:kartaobcana@tsbenesov.cz"
								className={'underline text-primary-300'}
							>
								kartaobcana&#064;tsbenesov.cz
							</a>
							<br />
							<a
								href="mailto:info@kartaobcana.cz"
								className={'underline text-primary-300'}
							>
								info&#064;kartaobcana.cz
							</a>
						</p>
					</div>
					<div className="mb-6">
						<p className="text-xl font-semibold text-gray-800 mb-1">
							Telefon:
						</p>
						<p className="text-gray-600">
							<a
								href="tel:+420317723311"
								className={'underline text-primary-300'}
							>
								+420 317 723 311
							</a>
						</p>
					</div>
				</BoxContent>
			</div>
		</div>
	)
}

export default Support
