import React, { useEffect, useState } from 'react'
import PortalHeader from '../../../compontents/PortalHeader'
import { getChildren } from '../../../api/CitizenApi'
import { toast } from 'react-toastify'
import Loader from '../../../compontents/Loader'
import ChildrenList from '../../../compontents/Citizen/ChildrenList'
import BoxContent from '../../../compontents/BoxContent'

const FamilyMembers = () => {
	const [children, setChildren] = useState(null)

	useEffect(() => {
		fetchChildren().then(data => setChildren(data))
	}, [])

	async function fetchChildren() {
		try {
			return await getChildren()
		} catch (e) {
			console.error(e.message || e)
			toast.error('Při zpracování požadavku došlo k chybě.')
		}
	}

	return (
		<div>
			<PortalHeader headline={'Členové rodiny'} />
			{children ? (
				<div className={'container max-w-5xl mx-auto'}>
					<BoxContent>
						{children.citizenList.length === 0 ? (
							<div>
								K Vašemu účtu není připojený žádný člen rodiny.
							</div>
						) : (
							<ChildrenList data={children.citizenList} />
						)}
					</BoxContent>
				</div>
			) : (
				<div className={'flex justify-center'}>
					<Loader />
				</div>
			)}
		</div>
	)
}

export default FamilyMembers
