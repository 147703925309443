import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userDataSelector } from '../../redux/selectors/userSelectors'
import { useLogout } from '../../hooks/useLogout'
import FeatherIcon from 'feather-icons-react'

const FrontNavbar = () => {
	const userSelector = useSelector(userDataSelector)
	const logout = useLogout()
	const [showMenu, setShowMenu] = useState(false)

	return (
		<nav className="bg-primary-300 relative">
			<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
				<div className="flex h-16 items-center justify-between">
					<div className="flex flex-shrink-0 items-center">
						<Link to={'/'}>
							<h1
								className={
									'text-white font-bold text-sm lg:text-xl'
								}
							>
								<span className={'text-secondary-300'}>
									Bene
								</span>
								šov
								<span className={'text-secondary-300'}>
									<sup
										className={'icon-benesov text-sm '}
									></sup>
									<span
										className={'block md:inline-block'}
									></span>
								</span>
								Bene
								<span className={'text-secondary-300'}>
									fit!
								</span>
							</h1>
						</Link>
					</div>
					<div className="sm:ml-6">
						<button
							className={
								'text-gray-300 hover:text-white px-2 py-2 text-sm font-medium flex sm:hidden gap-x-2'
							}
							onClick={() => setShowMenu(!showMenu)}
						>
							{showMenu ? (
								<FeatherIcon icon={'x'} size={20} />
							) : (
								<FeatherIcon icon={'menu'} size={20} />
							)}
						</button>
						<div
							className={`sm:flex ${showMenu ? 'bg-primary-300 z-10 absolute left-0 w-full flex flex-col' : 'hidden'}`}
						>
							{userSelector ? (
								<Fragment>
									<Link
										to={'/portal'}
										onClick={() => setShowMenu(false)}
										className="text-gray-300 hover:text-white px-3 py-3 sm:px-2 sm:py-2 text-sm font-medium"
									>
										Můj účet
									</Link>
									<button
										onClick={() => logout()}
										className="text-gray-300 hover:text-white px-3 py-3 sm:px-2 sm:py-2 text-sm font-medium"
									>
										Odhlásit se
									</button>
								</Fragment>
							) : (
								<Fragment>
									<Link
										to={'/prihlaseni'}
										onClick={() => setShowMenu(false)}
										className="text-gray-300 hover:text-white px-3 py-3 sm:px-2 sm:py-2 text-sm font-medium"
									>
										Již mám účet
									</Link>
									<Link
										to={'/registrace'}
										onClick={() => setShowMenu(false)}
										className="text-gray-300 hover:text-white px-3 py-3 sm:px-2 sm:py-2 text-sm font-medium"
									>
										Chci se registrovat
									</Link>
								</Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		</nav>
	)
}

export default FrontNavbar
