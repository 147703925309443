import React from 'react'
import FrontNavbar from '../Navbar/FrontNavbar'

const AuthLayout = ({ children }) => {
	return (
		<div>
			<FrontNavbar />
			{children}
		</div>
	)
}

export default AuthLayout
