import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authenticate, login, logout, register } from '../../api/AuthApi'

const userDataInitialState = {
	degree: null,
	degreeAfter: null,
	firstName: null,
	lastName: null,
	email: null,
	credit: null,
	token: null
}
const initialState = {
	loading: false,
	isLoad: false,
	error: null,
	userData: {
		degree: null,
		degreeAfter: null,
		firstName: null,
		lastName: null,
		email: null,
		credit: null,
		token: null
	}
}

export const loginUser = createAsyncThunk(
	'user/login',
	async ({ email, password }) => {
		const response = await login(email, password)
		if (response) {
			return response
		} else throw new Error(response.message)
	}
)

export const registerUser = createAsyncThunk(
	'user/register',
	async ({ data }) => {
		const response = await register(data)
		if (response) {
			return response
		} else throw new Error(response.message)
	}
)

export const logoutUser = createAsyncThunk('user/logout', async () => {
	const response = await logout()
	if (response) {
		return response
	} else throw new Error(response.message)
})

export const authenticateUser = createAsyncThunk(
	'user/authenticate',
	async () => {
		const response = await authenticate()
		if (response) {
			return response
		} else throw new Error(response.message)
	}
)

export const userslice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		logoutUser(state) {
			state.loading = false
			state.isLoad = false
			state.error = false
			state.userData = {
				degree: null,
				degreeAfter: null,
				firstName: null,
				lastName: null,
				email: null,
				credit: null,
				token: null
			}
		}
	},
	extraReducers: builder => {
		// loginUser
		builder.addCase(loginUser.pending, (state, action) => {
			state.loading = true
			state.isLoad = false
			state.error = false
		})

		builder.addCase(loginUser.fulfilled, (state, action) => {
			state.loading = false
			state.isLoad = true
			state.error = false
			if (action.payload) {
				state.userData = action.payload
			}
		})

		builder.addCase(loginUser.rejected, (state, action) => {
			state.loading = false
			state.isLoad = true
			state.error = true
			state.userData = userDataInitialState
		})

		// logout
		builder.addCase(logoutUser.pending, (state, action) => {
			state.loading = true
			state.isLoad = false
			state.error = false
		})

		builder.addCase(logoutUser.fulfilled, (state, action) => {
			state.loading = false
			state.isLoad = true
			state.error = true
			state.userData = userDataInitialState
		})

		builder.addCase(logoutUser.rejected, (state, action) => {
			state.loading = false
			state.isLoad = true
			state.error = true
			state.userData = userDataInitialState
		})

		// authenticateUser
		builder.addCase(authenticateUser.pending, (state, action) => {
			state.loading = true
			state.isLoad = false
			state.error = false
		})

		builder.addCase(authenticateUser.fulfilled, (state, action) => {
			state.loading = false
			state.isLoad = true
			state.error = true
			if (action.payload) {
				state.userData = {
					...state.userData,
					firstName: action.payload.firstName,
					lastName: action.payload.lastName,
					credit: action.payload.credit
				}
			}
		})

		builder.addCase(authenticateUser.rejected, (state, action) => {
			state.loading = false
			state.isLoad = true
			state.error = true
			state.userData = userDataInitialState
		})
	}
})
export default userslice.reducer
