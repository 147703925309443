import React from 'react'
import { Link } from 'react-router-dom'

const BoxContent = ({
	children,
	label,
	additionalClass,
	btnText,
	btnLink,
	showButton
}) => {
	return (
		<div
			className={`text-lg w-full bg-white shadow-lg rounded-lg overflow-hidden p-4 mb-8 ${additionalClass ? additionalClass : ''}`}
		>
			{(label || showButton) && (
				<div className={'flex justify-between items-center mb-7'}>
					{label && (
						<div>
							<p className={'text-md text-gray-400'}>{label}</p>
						</div>
					)}
					{showButton && (
						<div>
							<Link to={btnLink} className={'button-primary'}>
								{btnText}
							</Link>
						</div>
					)}
				</div>
			)}
			{children}
		</div>
	)
}

export default BoxContent
