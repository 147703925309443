import React, { useEffect, useState } from 'react'
import PortalNavbar from '../Navbar/PortalNavbar'
import Sidebar from '../Sidebar/Sidebar'
import { useDispatch } from 'react-redux'
import { authenticateUser } from '../../redux/slices/userslice'
import { useAuthCheckAndRedirect } from '../../hooks/useAuthCheckAndRedirect'

const PortalLayout = ({ children }) => {
	useAuthCheckAndRedirect()

	const dispatch = useDispatch()
	const [showSidebar, setShowSidebar] = useState(false)

	useEffect(() => {
		dispatch(authenticateUser())
	}, [dispatch])

	const toggleSidebar = () => {
		setShowSidebar(!showSidebar)
	}

	return (
		<div className={'module-portal'}>
			<PortalNavbar
				showSidebar={showSidebar}
				toggleSidebar={() => toggleSidebar()}
			/>
			<div className={'flex'}>
				<Sidebar show={showSidebar} />
				<main
					className={`bg-gray-200 min-h-screen lg:min-h-full lg:w-5/6 w-full p-3 md:p-6`}
				>
					<div className={'main-content'}>{children}</div>
					<footer
						className={
							'flex flex-col md:flex-row mt-5 md:mt-3 text-center md:text-start justify-between'
						}
					>
						<div className={'text-gray-500'}>
							Vytvořil{' '}
							<a href="https://deepvision.cz/">
								DEEP VISION s.r.o.
							</a>{' '}
							pro město Benešov a TS Benešov.
						</div>
						<div className={'mt-3 md:mt-0'}>
							<span className={'text-gray-400'}>
								{process.env.REACT_APP_BITBUCKET_VERSION}
							</span>
						</div>
					</footer>
				</main>
			</div>
		</div>
	)
}

export default PortalLayout
