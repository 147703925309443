import React from 'react'
import { Link } from 'react-router-dom'
import Badge from '../Badge'
import moment from 'moment'

const ChildrenList = ({ data }) => {
	return (
		<table className="grid table-auto w-full">
			<thead className={'grid hidden xl:block'}>
				<tr
					className={
						'grid-child-template grid text-left text-gray-400 gap-4'
					}
				>
					{/*<th></th>*/}
					<th>Jméno</th>
					<th>E-mail</th>
					<th>Telefon</th>
					<th>V systému od</th>
					<th>Stav</th>
					<th></th>
				</tr>
			</thead>
			<tbody className={'grid gap-10 xl:gap-4'}>
				{data.map((item, index) => (
					<tr
						className={`grid-child-template grid gap-3 ${data.length - 1 !== index && 'border-b pb-10 lg:pb-5'}`}
						key={index}
					>
						<td
							className={
								'flex flex-wrap items-center gap-x-3 p-0'
							}
						>
							<div
								className={
									'font-semibold text-gray-400 w-full xl:hidden'
								}
							>
								Jméno
							</div>
							<div className="rounded-full h-8 w-8 text-sm flex items-center justify-center bg-gradient-to-r from-primary-100 to-primary-500 text-white font-semibold">
								{item.firstName[0]}
								{item.lastName[0]}
							</div>
							<div>
								<h3 className="text-lg text-gray-800 font-semibold">
									{item.firstName} {item.lastName}
								</h3>
								<p className="text-gray-500 text-sm">
									ID: {item._id.substring(0, 8)}...
								</p>
							</div>
						</td>
						<td className={'p-0'}>
							<div
								className={
									'font-semibold text-gray-400 xl:hidden'
								}
							>
								E-mail
							</div>
							<p className="font-semibold text-gray-600">
								{item.email}
							</p>
						</td>
						<td className={'p-0'}>
							<div
								className={
									'font-semibold text-gray-400 xl:hidden'
								}
							>
								Telefon
							</div>
							<p className="font-semibold text-gray-600">
								{item.phone}
							</p>
						</td>
						<td className={'p-0'}>
							<div
								className={
									'font-semibold text-gray-400 xl:hidden'
								}
							>
								Datum
							</div>
							{moment(item.createdAt).format('D. M. Y')}
						</td>
						<td className={'p-0'}>
							<div
								className={
									'font-semibold text-gray-400 xl:hidden'
								}
							>
								Stav
							</div>
							<Badge
								type={item.active ? 'success' : 'danger'}
								text={item.active ? 'Aktivní' : 'Neaktivní'}
							/>
						</td>
						<td className={'p-0'}>
							<Link
								to={`/portal/clenove-rodiny/${item._id}`}
								className="button-primary block"
							>
								Detail
							</Link>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}
export default ChildrenList
