import { createSelector } from '@reduxjs/toolkit'

const userRootReducer = state => state.userReducer

export const userDataSelector = createSelector(userRootReducer, state => {
	return state.userData.email ? state.userData : null
})

export const userTokenSelector = createSelector(userRootReducer, state => {
	if (state.userData) return state.userData.token
	else return null
})

export const userCreditSelector = createSelector(userRootReducer, state => {
	if (state.userData) return state.userData.credit
	else return null
})

export const userAuthStatus = createSelector(userRootReducer, state => {
	return state.userData.token !== null
})
