import React from 'react'
import SidebarLink from 'compontents/Sidebar/SidebarLink'
import { portalSidebarLinks } from 'variables/portalSidebarLinks'
import FeatherIcons from 'feather-icons-react'
import { useLogout } from '../../hooks/useLogout'

const Sidebar = ({ show }) => {
	const logout = useLogout()

	return (
		<div
			className={`min-h-[calc(100vh-60px)] min-w-[250px] fixed bg-secondary-300 z-50 ${show ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 lg:w-1/6 lg:relative lg:translate-x-0`}
		>
			<div>
				<ul className={'py-3'}>
					{portalSidebarLinks.map((item, index) => (
						<SidebarLink
							key={index}
							label={item.label}
							link={item.link}
							icon={item.icon}
						/>
					))}
					<li>
						<button
							onClick={() => logout()}
							className={
								'flex w-full justify-items-start items-center px-7 py-5 text-primary-300 hover:bg-yellow-300'
							}
						>
							<FeatherIcons icon={'log-out'} />{' '}
							<span className={'ml-4 text-sm'}>Odhlásit se</span>
						</button>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Sidebar
