import React from 'react'
import { Link } from 'react-router-dom'

const UserCredit = ({ userCredit }) => {
	return (
		<div
			className={'text-center leading-3 sm:text-start sm:leading-normal'}
		>
			{userCredit && (
				<span className={'text-white'} title={'Bene kreditů'}>
					<Link to={'/portal/historie'}>
						{userCredit}{' '}
						<span
							className={
								'block text-xs sm:inline-block sm:text-base'
							}
						>
							Bene
						</span>
					</Link>
				</span>
			)}
		</div>
	)
}

export default UserCredit
