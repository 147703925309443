import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../redux/slices/userslice'

export const useLogout = path => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	return () => {
		dispatch(logoutUser())
		if (!path) {
			navigate('/')
		} else {
			navigate(path)
		}
	}
}
