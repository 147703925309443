import React from 'react'
import PortalHeader from 'compontents/PortalHeader'
import PortalGreeting from 'compontents/PortalGreeting'
import { useSelector } from 'react-redux'
import { userDataSelector } from '../../redux/selectors/userSelectors'
import TouchPointTile from '../../compontents/TouchPointTile'

const Dashboard = () => {
	const userSelector = useSelector(userDataSelector)

	return (
		<div>
			{userSelector && (
				<PortalHeader
					headline={
						<PortalGreeting
							name={userSelector.firstName}
							gender={'m'}
						/>
					}
				/>
			)}
			<div className="w-4/5 mx-auto">
				<div className={'grid grid-cols-1 md:grid-cols-3 gap-5'}>
					<TouchPointTile
						image={'busstation.jpg'}
						title={'Autobus'}
					/>
					<TouchPointTile image={'church.jpg'} title={'Karlov'} />
					<TouchPointTile image={'ecotrain.jpg'} title={'Vláček'} />
					<TouchPointTile image={'parking.jpg'} title={'Parkování'} />
					<TouchPointTile
						image={'swimmingpool.jpg'}
						title={'Bazén'}
					/>
					<TouchPointTile
						image={'technicalservices.jpg'}
						title={'Sběrný dvůr'}
					/>
				</div>
			</div>
		</div>
	)
}

export default Dashboard
