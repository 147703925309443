import React from 'react'
import FormInput from '../Input/FormInput'
import { useForm } from 'react-hook-form'
import { updateChild, updateCitizen } from '../../../api/CitizenApi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { accountEditValidation } from '../../../variables/formsValidation/accountEditValidation'

const AccountForm = ({ defaultValues, childId }) => {
	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		defaultValues,
		mode: 'onBlur',
		resolver: yupResolver(accountEditValidation(childId))
	})

	const onSubmit = async data => {
		if (!childId) {
			await updateCitizen(data)
				.then(res => {
					if (res) {
						toast.success('Účet byl aktualizován')
						navigate('/portal/muj-ucet')
					} else {
						toast.error('Nelze aktualizovat profil')
					}
				})
				.catch(err => {
					console.error('Nelze aktualizovat profil')
				})
		} else {
			await updateChild(data, childId)
				.then(res => {
					if (res) {
						toast.success('Účet byl aktualizován')
						navigate('/portal/clenove-rodiny')
					} else {
						toast.error('Nelze aktualizovat profil')
					}
				})
				.catch(err => {
					console.error('Nelze aktualizovat profil')
				})
		}
	}

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={'mb-3'}>
					<FormInput
						name={'firstName'}
						type={'text'}
						label={'Jméno'}
						errors={errors}
						readonly={true}
						register={register}
						maps
					/>
				</div>
				<div className="mb-3">
					<FormInput
						name={'lastName'}
						type={'text'}
						label={'Příjmení'}
						errors={errors}
						readonly={true}
						register={register}
					/>
				</div>
				<div className="mb-3">
					<FormInput
						name={'email'}
						type={'text'}
						label={'E-mail'}
						required={true}
						errors={errors}
						readonly={false}
						register={register}
					/>
				</div>
				<div className="mb-3">
					<FormInput
						name={'phone'}
						type={'text'}
						label={'Telefon'}
						required={true}
						errors={errors}
						readonly={false}
						register={register}
					/>
				</div>
				<div className="mb-3">
					<FormInput
						name={'street'}
						type={'text'}
						label={'Ulice'}
						errors={errors}
						readonly={true}
						register={register}
					/>
				</div>
				<div className="mb-3">
					<FormInput
						name={'houseNumber'}
						type={'text'}
						label={'Číslo domu'}
						errors={errors}
						readonly={true}
						register={register}
					/>
				</div>
				<div className="mb-3">
					<FormInput
						name={'city'}
						type={'text'}
						label={'Město'}
						errors={errors}
						readonly={true}
						register={register}
					/>
				</div>
				<div className="mb-3">
					<FormInput
						name={'zip'}
						type={'text'}
						label={'ZIP'}
						errors={errors}
						readonly={true}
						register={register}
					/>
				</div>
				<button type="submit" className="button-primary mt-3">
					Aktualizovat profil
				</button>
			</form>
		</div>
	)
}

export default AccountForm
