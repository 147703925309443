import React, { useCallback, useEffect, useState } from 'react'
import { getChild } from '../../../api/CitizenApi'
import { toast } from 'react-toastify'
import BoxContent from '../../../compontents/BoxContent'
import AccountForm from '../../../compontents/Forms/Portal/AccountForm'
import Loader from '../../../compontents/Loader'
import { useNavigate, useParams } from 'react-router-dom'

const FamilyMemberEdit = () => {
	const [citizen, setCitizen] = useState(null)
	const navigate = useNavigate()
	let { id: childId } = useParams()

	const getChildData = useCallback(async () => {
		try {
			const data = await getChild(childId)
			setCitizen(data.citizen)
		} catch (error) {
			console.error(error.message || error)
			toast.error('Při zpracování požadavku došlo k chybě.')
		}
	}, [childId])

	useEffect(() => {
		if (!childId) {
			toast.info('ID nenalezeno')
			navigate('/portal/clenove-rodiny')
		}

		getChildData().then(() => console.info('Data loaded..'))
	}, [navigate, childId, getChildData])

	return (
		<BoxContent label={'Upravit profil'} additionalClass={'max-w-4xl'}>
			{citizen ? (
				<AccountForm defaultValues={citizen} childId={childId} />
			) : (
				<Loader />
			)}
		</BoxContent>
	)
}

export default FamilyMemberEdit
