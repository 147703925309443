import React, { useState } from 'react'
import { Plus, Minus } from 'feather-icons-react'

const FaqItem = ({ question, answer }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div className="my-4 bg-white">
			<button
				className="w-full text-left p-4 bg-gray-100 focus:outline-none focus:ring"
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className="flex justify-between items-center">
					<span className="text-lg font-semibold">{question}</span>
					<span>{isOpen ? <Minus /> : <Plus />}</span>
				</div>
			</button>
			{isOpen && (
				<div
					className={
						'faq-item-answer mt-2 p-4 bg-white rounded-md shadow-md'
					}
					dangerouslySetInnerHTML={{ __html: answer }}
				/>
			)}
		</div>
	)
}

export default FaqItem
