import * as yup from 'yup'
import { ApiAxios } from '../../axios/axiosConfig'

/* eslint-disable no-template-curly-in-string */

export const accountEditValidation = childId =>
	yup.object().shape({
		email: yup
			.string()
			.trim()
			.max(150, 'Max. ${max} znaků')
			.email('Zadejte správný formát e-mailové adresy')
			.test('isEmailUnique', 'E-mail musí být unikátní', async value => {
				return await ApiAxios.post(
					childId
						? `/citizen/is-child-email-unique/${childId}`
						: `/citizen/is-my-email-unique`,
					{
						email: value
					}
				)
					.then(res => {
						return res.data.result
					})
					.catch(err => {
						console.error(err.response.data.message)
					})
			})
	})
