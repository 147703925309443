import React from 'react'
import PortalHeader from '../../../compontents/PortalHeader'
import BoxContent from '../../../compontents/BoxContent'
import AddCreditForm from "../../../compontents/Forms/Portal/AddCreditForm";

const AddCredit = () => {
	return (
		<>
			<PortalHeader headline={'Dobití kreditu'} />
			<BoxContent additionalClass={'max-w-2xl mx-auto'}>
				<>
					<AddCreditForm />
				</>
			</BoxContent>
		</>
	)
}

export default AddCredit
