import React, { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

const NumberFormatCustom = forwardRef(({ onChange, ...props},  ref) => (
	<NumericFormat
		{...props}
		getInputRef={ref}
		min={100}
		onValueChange={(values) => {
			onChange(values.floatValue);
		}}
		className={`border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3 bg-gray-50 ${props.className}`}
	/>
));

export default NumberFormatCustom;